import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// import { useLunr } from 'react-lunr'
import { useFlexSearch } from 'react-use-flexsearch'

import { Context } from '../provider'

import { ICONS } from '../utils/constants'
import Icon from '../components/icon'
import Footer from '../components/footer'

import {
  Search,
  Wrap,
  UI,
  Input,
  Clear,
  Close,
  Results,
  Inner,
  Section,
  Heading,
} from '../styled/search'

import {
  GridWrapper,
  Grid,
  Content,
  Title,
  //Carousel,
} from '../styled/grid'



const Component = props => {
  const {
    headerIsExpanded,
    //searchIsOpen,
    //setSearchIsOpen
  } = props

  const { 
    lockScroll, 
    setLockScroll,
    searchIsOpen,
    setSearchIsOpen
  } = React.useContext(Context)

  const queryData = useStaticQuery(graphql`
    query {
      localSearchGeneral {
        index
        store
      }
      localSearchWork {
        index
        store
      }
    }
  `)

  const generalIndex = queryData.localSearchGeneral.index
  const generalStore = queryData.localSearchGeneral.store

  // console.log(generalStore)

  const workIndex = queryData.localSearchWork.index
  const workStore = queryData.localSearchWork.store

  // console.log(workStore)

  const inputRef = React.useRef('')
  const [query, setQuery] = React.useState('')
  const [queryEngaged, setQueryEngaged] = React.useState('')

  React.useEffect(() => {
    if(searchIsOpen) {
      setTimeout(() => {
        inputRef?.current.focus()
      }, 300)
    }
  }, [searchIsOpen])

  const generalResults = useFlexSearch(query, generalIndex, generalStore)
  const workResults = useFlexSearch(query, workIndex, workStore)


  const resetSearch = () => {
    setSearchIsOpen(false)
    setLockScroll(false) 

    setTimeout(() => {
      setQuery('')
      setQueryEngaged('')
    }, 300)
  }

  return (
    <Search
      searchIsOpen={ searchIsOpen }
    >
      <UI 
        headerIsExpanded={ headerIsExpanded }
      >         
        <Input 
          placeholder='Search'
          value={ query }
          ref={ inputRef }
          onChange={ 
            (event) => { 
              setQuery(event.target.value) 
              setQueryEngaged(true)
            }
          }
        />
        <Clear
          searchValue={ query }
          onClick={ () => {
            setQuery('')
            // setQueryEngaged(false)
          }}
        >
          Clear
        </Clear>
        <Close
          onClick={ resetSearch }
        >
          <Icon 
            icon={ ICONS.CLOSE } 
          />
        </Close>
      </UI>
      <Wrap headerIsExpanded={ headerIsExpanded }>
        <Results queryEngaged={ queryEngaged }>
          <Inner>
            { workResults.length > 0 &&
              <Section>
                <>
                  <Heading>
                    Work
                  </Heading>

                  <GridWrapper>
                    <Grid>
                      { workResults.map((item, index) => {
                        // const image = item?.image
                        //const hero = item?.hero.layouts
                        // const imageData = item?.hero?.layouts ? getImage(item?.hero?.layouts[0].image.localFile) : null

                        // console.log(item)

                        const image = item.featuredImage?.node ? item.featuredImage?.node?.localFile : item.hero?.layouts[0].image?.localFile
                        const imageData = getImage(image)


                        // console.log(image)

                        if(!imageData) {
                          return (
                            <Link key={ item.id } to={ item.uri }> 
                              { item.title }
                            </Link>
                          )
                        }

                        return (
                          <Link key={ item.id } to={ item.uri } onClick={ resetSearch }>
                            <Content>
                              <GatsbyImage 
                                image={ imageData } 
                                alt="" 
                              />

                            <Title visible>
                              { item.title }
                            </Title>

                            </Content>
                          </Link>
                        )
                      })}
                    </Grid>
                  </GridWrapper>

                  {/*
                  <ul>
                    { workResults.map(( result ) => (
                      <li key={ result.id }>
                        <Link to={ result.uri }> 
                          { result.title }
                        </Link>
                      </li>
                    ))}
                  </ul>
                  */}
                </>
              </Section>
            }

            { generalResults.length > 0 &&
              <Section>
                <>
                  <Heading>
                    All results
                  </Heading>
                  <ul>
                    { generalResults.map(( result ) => (
                      <li key={ result.id }>
                        <Link to={ result.uri } onClick={ resetSearch }> 
                          { result.title }
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              </Section>
            }

            { (!generalResults.length && !workResults.length && query) &&
              <p>No results</p>
            }
          </Inner>
        </Results>
        {/*
        <Footer theme='dark' />
        */}
      </Wrap>
    </Search>
  )
}

export default Component