import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { sm, md, lg } from '../utils/breakpoints'

import { 
  componentStyle,
  buttonStyle,
} from './general'

export const Search = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  // bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: var(--color-red);
  overflow: hidden;

  height: ${ props =>
    props.searchIsOpen ? `100vh` : `0` };

  transition: height 0.25s ease-in-out;


  /*
  display: ${ props =>
    props.searchIsOpen ? `block` : `none` };
  */
`

export const Wrap = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: calc(100vh - 65px);
  // min-height: 100vh;
  
  transition: transform 0.25s ease-in-out;

  // height: calc(100vh - 65px);

  height: ${ props =>
    props.headerIsExpanded ? `calc(100vh - 65px);` : `calc(100vh - 49px);` };

  overflow: auto;
  
  transform: ${ props =>
    props.headerIsExpanded ? `translateY(0px);` : `translateY(-16px);` };

`

export const UI = styled('div')`
  ${ componentStyle };
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 65px;
  transition: transform 0.25s ease-in-out;
  //margin-top: 38px;

  /*
  margin-top: ${ props =>
    props.headerIsExpanded ? `38px` : `22px` }; 
  */ 

  
  transform: ${ props =>
    props.headerIsExpanded ? `translateY(0px);` : `translateY(-16px);` };
  
`

export const UIItemStyle = css`
   margin-bottom: 8px;
`

export const Input = styled('input')`
  ${ buttonStyle };
  ${ UIItemStyle };
  margin-right: auto;
  flex: 1;
  appearance: none;

  background-color: transparent;
  color: var(--color-white);

  &::placeholder {
    // line-height: 1;
    color: var(--color-white);
    opacity: 0.4;
  }

  line-height: revert;
`

export const Clear = styled('button')`
  ${ buttonStyle };
  ${ UIItemStyle }
  margin-right: 14px;
  color: var(--color-white);
  opacity: 0.4;

  display: ${ props =>
    props.searchValue ? `block` : `none` };
`

export const Close = styled('button')`
  ${ buttonStyle };
  ${ UIItemStyle }
`

export const Results = styled('div')`
  display: flex;
  flex: 1;
  background-color: var(--color-white);
  padding: 20px 14px;

  transition: transform 0.5s ease-in-out;
  transform: ${ props =>
    props.queryEngaged ? `translateY(0)` : `translateY(100vh)` };

  ${ lg } {
    padding: 25px 20px;
  }
`

export const Inner = styled('div')`
  width: 100%;
`

export const Section = styled('div')`
  // margin-bottom: 1rem;
  margin-bottom: 80px;
`

export const Heading = styled('h4')`
  color: var(--color-grey);
  margin-bottom: 0.25rem
`