import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'

// import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { GlobalStyles } from 'twin.macro'
import * as normalize from 'normalize.css'

import { Context } from '../provider'

import Search from '../components/search'
import Header from '../components/header'
import Footer from '../components/footer'
import CookieBar from '../components/cookieBar'

import { 
  Canvas,
  Splash,
  Wrap,
  Portal,
  Modal,
  Main 
} from '../styled/general'

/**
 * Component
 */
const LayoutComponent = ({ children, pageContext }) => {

  const { lockScroll, setLockScroll } = React.useContext(Context);
  // const [searchIsOpen, setSearchIsOpen] = React.useState(false)
  const [headerIsExpanded, setHeaderIsExpanded] = React.useState(true)
  const [intro, setIntro] = React.useState(true)
  const [removeIntro, setRemoveIntro] = React.useState(false)
  // const [lockScroll, setLockScroll] = React.useState(false)

  const path = typeof window !== 'undefined' ? window.location.pathname : '';
  const expandedHeaderPaths = [
    '/form/',
    '/us/',
    '/with/',
    '/',
  ]

  React.useEffect(() => {

    function set100vhVar() {

      // console.log(window.innerHeight)
      let windowWidth = window.innerWidth

      // If less than most tablets, set CSS var to window height.
      let value = '100vh'
      
      if (window.innerWidth <= 1024 && window.innerWidth !== windowWidth) {
        value = `${window.innerHeight}px`
        windowWidth = window.innerWidth
      }
      
      document.documentElement.style.setProperty("--unit-100vh", value)
    }

    set100vhVar()
    window.addEventListener('resize', set100vhVar)

  }, [])

  React.useEffect(() => {
    // const isHeaderExpanded = expandedHeaderPaths.includes(path)
  
    let isHeaderExpanded = false

    if(expandedHeaderPaths.includes(path)) {
      isHeaderExpanded = true
    } else {
      const pattern = /^\/page\//

      isHeaderExpanded = pattern.test(path)
    }
    
    setHeaderIsExpanded(isHeaderExpanded)
  }, [path]);

  React.useEffect(() => {
    if(path === '/') {
      setLockScroll(true)

      setTimeout(() => {
        setIntro(false)
      }, 2950)
  
      setTimeout(() => {
        setRemoveIntro(true)
        setLockScroll(false)
      }, 3450)
    } else {
      setIntro(false)
      setRemoveIntro(true)
      setLockScroll(false)
    }
  }, [])

  // console.log(wp)

  // console.log(lockScroll)

  return (
    /* <SEOContext.Provider value={{ global: seo }}> */
    <>
      <GlobalStyles />
      <Global
        styles={css`
          ${ normalize }

          html {
            overflow-x: hidden;
            overscroll-behavior-x: none;
            // overflow: hidden;
            overflow: ${ lockScroll ? 'hidden' : 'auto'}
          }

          body {
            font-family: 'Antarctica Beta', 'Helvetica', 'Arial', 'sans-serif';
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 18px;
            line-height: 1.1;
            // overflow-x: hidden;
            overscroll-behavior-x: none;
            background-color: var(--color-red);
            // overflow: ${ lockScroll ? 'hidden' : 'auto'}
          }
        `}
      />
        <Canvas>
          <Search 
            // searchIsOpen={ searchIsOpen }
            // setSearchIsOpen={ setSearchIsOpen }
            headerIsExpanded={ headerIsExpanded }
          />

          <Header 
            // searchIsOpen={ searchIsOpen }
            // setSearchIsOpen={ setSearchIsOpen }
            headerIsExpanded={ headerIsExpanded }
            intro={ intro }
          />

          <Portal 
            id="portal"
            headerIsExpanded={ headerIsExpanded }
          />

          <Wrap headerIsExpanded={ headerIsExpanded }>
            { !removeIntro &&
              <Splash intro={ intro } />
            }

            <div>
              <Main>
                { children }
              </Main>
            </div>
            { pageContext.layout != 'noFooter' &&
              <Footer />
            }
          </Wrap>

          <CookieBar intro={ intro } />
        </Canvas>
    </>
    /* </SEOContext.Provider> */
  )
}

/*
LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
}
*/

export default LayoutComponent
