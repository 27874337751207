import './src/styles/variables-css.css'
import Provider from './src/provider'
import ReactDOM from 'react-dom/client'

/* eslint-disable */
export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  locations.push(window.location.pathname)
  window.previousPath = locations[locations.length - 1]
}
/* eslint-enable */

export const wrapRootElement = Provider


export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}