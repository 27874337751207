import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link as GatsbyLink } from 'gatsby'

import { sm, md, lg } from '../utils/breakpoints'

export const Canvas = styled('div')`
    background-color: var(--color-white);
`

export const Splash = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;

  background-color: var(--color-red);
  // transition: transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;

  /*
  transform: ${ props =>
    props.intro ? `translateY(0px);` : `translateY(calc(100vh + 65px));` };
  */

  opacity: ${ props =>
    props.intro ? `1` : `0` };
`

export const Wrap = styled('div')`
  ${ lg } {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 65px);
  }

  overflow: hidden;

  transition: transform 0.25s ease-in-out;
  transform: ${ props =>
    props.headerIsExpanded ? `translateY(0px);` : `translateY(-16px);` };
`



export const Main = styled('main')`
  display: flow-root;
  position: relative;
  counter-reset: footnotes;
  
  // transform: translateY(-16px);


`

export const componentStyle = css`
  padding: 0 14px;

  ${ lg } {
    padding: 0 20px;
  }
`

export const componentDistanceStyle = css`
  // margin: 14px 0;

  ${ lg } {
    // margin: 20px 0;
  }
`

export const ComponentDistance = styled('div')`
  // margin-top: 80px;
  // margin-bottom: 80px;

  ${props => {
    switch(props.margins?.desktop?.top) {
      case 'none':
        return `
          margin-top: 0;
        `
      case 'small':
        return `
          margin-top: 14px;
        `
      default:
        return `
          margin-top: 80px;
        ` 
    }
  }}

  ${props => {
    switch(props.margins?.desktop?.bottom) {
      case 'none':
        return `
          margin-bottom: 0;
        `
      case 'small':
        return `
          margin-bottom: 14px;
        `
      default:
        return `
          margin-bottom: 80px;
        ` 
    }
  }}
`

export const linkStyle = css`
  transition: color 0.1s ease-in-out;

  @media (hover: hover) {
    &:hover {
      color: var(--color-red);
    }
  }
`

export const buttonStyle = css`
  appearance: none;
  // -webkit-tap-highlight-color: transparent;
  line-height: inherit;

  &:focus {
    outline: none;
  }
`

export const PageWrap = styled('div')`
  margin-top: 20px;
`

export const Link = styled(GatsbyLink)`
  ${ buttonStyle };
`

export const Footnote = styled('span')`
  color: var(--color-red);
  vertical-align: super; /* 2 */
  font-size: 0.5em; /* 3 */
  // margin-left: 2px;
`

/*
export const ButtonText = styled('button')`
  ${ buttonStyle };
  ${ linkStyle };
`
*/

export const Portal = styled('div')`

`

export const Modal = styled('div')`
  position: fixed;
  //top: 49px;
  top: 65px;
  //right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: calc(100vh - 65px);

  z-index: 1000;
  background-color: var(--color-white);

  ${ componentStyle };

  overflow: scroll;
  overscroll-behavior: contain;

  .modal-inner {
    outline: none;
  }
`

export const NotFound = styled('div')`
  ${ componentStyle };
  margin-top: 20px;
  line-height: 22px;

  button {
    ${ linkStyle };
  }
`