import * as React from 'react';
import PropTypes from 'prop-types'

const Icon = props => {
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    path: {
      fill: props.isActive ? `var(--color-red)` : props.color,
    },
  };

  return (
    <svg
      style={ styles.svg }
      width={ `${props.size}px` }
      height={ `${props.size}px` }
      viewBox="0 0 18 18"
    >
      <path
        style={ styles.path }
        d={ props.icon }
      ></path>
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

Icon.defaultProps = {
  size: 18,
  color: '#FFFFFF',
};

export default Icon;