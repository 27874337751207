import * as React from 'react'
import styled from '@emotion/styled'

import { sm, md, lg } from '../utils/breakpoints'

export const GridWrapper = styled('div')`
  position: relative;
`

export const GridGUI = styled('div')`
  //position: absolute;
  //top: 16px;
  //left: 16px;
  //z-index: 10;
  margin: 20px 14px;

  ${lg} {
    margin: 20px;
  }
`

export const GridToggleAll = styled('button')`
  padding: 5px;
  margin: -5px;

  &:focus {
    outline: none;
  }
`

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // margin-bottom: 16px; /* TODO: rem instead of px? */

  ${lg} {
    grid-template-columns: repeat(4, 1fr);
  }

`

export const Title = styled('div')`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  align-items: center;
  justify-content: center;

  // display: none;

  display: flex;
  
  transition: opacity 0.085s ease-in-out;

  opacity: ${ props =>
    props.visible ? `1` : `0` };
  
  /*
  display: ${ props =>
    props.visible ? `flex;` : `none;` };
  */
`

export const Content = styled('div')`
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  
  aspect-ratio: 1;
  padding: 5px;

  @media (hover: hover) {
    &:hover {
      ${Title} {
        // display: flex;
        opacity: 1;
      }
    }
  }
`

export const SVGImage = styled('img')`
  width: 100%;
  height: 100%;
`

export const Carousel = styled('div')`
  position: relative;

  width: 100%;
  // height: 100%;
  padding-bottom: 100%;
  pointer-events: none;

  .flickity-enabled {
    // height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .flickity-slider {
    transform: none !important;
  }

  .carousel-cell {
    width: 100%;
    left: 0 !important;
    opacity: 0;
    z-index: -1;
    transform: none!important;

    &.is-selected {
      opacity: 1;
      z-index: 0
    }
  }
`
