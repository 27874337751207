exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-types-client-js": () => import("./../../../src/templates/types/client.js" /* webpackChunkName: "component---src-templates-types-client-js" */),
  "component---src-templates-types-page-js": () => import("./../../../src/templates/types/page.js" /* webpackChunkName: "component---src-templates-types-page-js" */),
  "component---src-templates-types-post-js": () => import("./../../../src/templates/types/post.js" /* webpackChunkName: "component---src-templates-types-post-js" */),
  "component---src-templates-types-work-js": () => import("./../../../src/templates/types/work.js" /* webpackChunkName: "component---src-templates-types-work-js" */)
}

