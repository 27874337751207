import * as React from 'react'
import styled from '@emotion/styled'
import { sm, md, lg } from '../utils/breakpoints'

import {
  linkStyle,
  buttonStyle,
} from '../styled/general'

export const Mailchimp = styled('div')`
  position: relative;
  display: flex;
`

export const Toggle = styled('button')`
  ${ linkStyle };
  ${ buttonStyle };
  //transition: opacity 0.1s ease-in-out;

  ${props => {
    if (props.engaged || props.submitted) {
      return `
        opacity: 0;
        pointer-events: none;
      `
    } else {
      return `
        opacity: 1;
      `
    }
  }}
`

export const Signup = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  ${props => {
    if (props.engaged && !props.message) {
      return `
        opacity: 1;
      `
    } else {
      return `
        pointer-events: none;
        opacity: 0;
      `
    }
  }}
`

export const InputWrapper = styled('div')`
  position: relative;
  flex: 1;
  margin-right: 7px;

  &:before {
    //content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background-color: black
  }

  &:after {
    //content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4px;
    background-color: black
  }
`

export const Input = styled('input')`
  appearance: none;
  ${ buttonStyle };
  background-color: transparent;
  color: var(--color-black);
  width: 100%;

  &::placeholder {
    line-height: 1;
    color: var(--color-grey);
    opacity: 0.4;
  }
`

export const Submit = styled('button')`
  ${ buttonStyle };
  transition: opacity 0.1s ease-in-out;

  opacity: ${ props =>
    props.email ? `1` : `0` };
`

export const Result = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  user-select: none;
  color: var(--color-red);

  ${props => {
    if (props.message) {
      return `
        opacity: 1;
      `
    } else {
      return `
        pointer-events: none;
        opacity: 0;
      `
    }
  }}
`
