import * as React from 'react'

import { Context } from '../provider'

import { ICONS } from '../utils/constants';
import Icon from '../components/icon'
import {
  Header,
  Inner,
  Nav,
  NavItem,
  NavLink,
  ButtonSearch,
} from '../styled/header'

const links = [
  {
    title: 'Form',
    path: '/form/'
  },
  {
    title: 'Us',
    path: '/us/'
  },
  {
    title: 'With',
    path: '/with/',
  },
  {
    title: 'Love',
    path: '/'
  }
]


const HeaderComponent = ({ 
  // setSearchIsOpen, 
  headerIsExpanded,
  intro 
}) => {
  const { 
    lockScroll, 
    setLockScroll,
    // searchIsOpen,
    setSearchIsOpen
  } = React.useContext(Context)

  return (
    <Header
      headerIsExpanded={ headerIsExpanded }
    >
      <Inner>
        <Nav intro={ intro } className={ intro ? `intro` : `` }>
          {links.map((link, index) => (
            <NavItem key={ link.path } intro={ intro } style={{ '--animation-order': `${ index + 1 }` }}>
              <NavLink
                to={ link.path }
                activeClassName='active'
              >
                { link.title }
              </NavLink>

            </NavItem>
          ))}
          <NavItem className="search" intro={ intro }>
            <ButtonSearch onClick={ () => {
              setSearchIsOpen(true)
              setLockScroll(!lockScroll)
            }}>
              <Icon 
                icon={ICONS.SEARCH} 
              />
            </ButtonSearch>
          </NavItem>
        </Nav>
      </Inner>
    </Header>
  )
}

export default HeaderComponent