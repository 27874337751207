import * as React from 'react'
import styled from '@emotion/styled'
import { 
  Link as GatsbyLink,
} from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"

import { 
  componentStyle,
} from './general'

import { sm, md, lg } from '../utils/breakpoints'

export const CookieBar = styled('aside')`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  ${ componentStyle };
  background-color: var(--color-red);
  opacity: ${ props =>
    props.isDismissed ? `0` : `1` };
  transform: ${ props =>
    props.intro ? `translateY(100%)` : `translateY(0)` };
  transition: opacity 0.175s ease-in-out,transform 0.275s cubic-bezier(0.20, 0.15, 0, 1) 0.5s;
  pointer-events: ${ props =>
    props.isDismissed ? `none` : `auto` };
`

export const Inner = styled('div')`
  padding: 16px 0 20px;
  line-height: 1.22;

  ${ lg } {
    display: flex;
    padding: 16px 0 13px;
  }
`

export const Header = styled('header')`
  display: flex;
`

export const Heading = styled('h3')`
  text-transform: uppercase;
  margin-right: auto;
`

export const Content = styled('p')`
  margin-top: 1rem;
  margin-right: 2rem;

  ${ lg } {
    margin-top: initial;
    margin-left: 1rem;
  }
`

export const Actions = styled('div')`
  ${ lg } {
    margin-left: auto;
  }
`

export const PrimaryAction = styled(GatsbyLink)`
  text-decoration: underline;
  margin-right: 2rem;

  &:hover {
    text-decoration: none;
  }
`

export const SecondaryAction = styled('button')`
  color: var(--color-white);
  transition: opacity 0.125s ease-in-out;
    
  ${props => {
    if (props.hiddenSM) {
      return `
        display: none;

        ${ lg } {
          display: initial;
        }
      `
    }

    if (props.visibleSM) {
      return `
        display: block;

        ${ lg } {
          display: none;
        }
      `
    }
  }}

  &:hover {
    opacity: 0.4;
  }
`
