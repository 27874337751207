import * as React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { ICONS } from '../utils/constants';
import Icon from '../components/icon'

import {
  Mailchimp,
  Toggle,
  Signup,
  InputWrapper,
  Input,
  Submit,
  Result,
} from '../styled/mailchimp'


const MailchimpComponent = ({ block }) => {
  const inputRef = React.useRef()
  const wrapperRef = React.useRef(null);
  const [engaged, setEngaged] = React.useState(false)
  const [email, setEmail] = React.useState(null)
  const [message, setMessage] = React.useState(null)
  const [submitted, setSubmitted] = React.useState(false)

  const handleSubmit = async (event, email) => {    
    const result = await addToMailchimp(email)
    handleResult(result)
  }

  const handleResult = ({ msg, result }) => {
    setSubmitted(true)

    if(result === 'success') setMessage("You're subscribed!")

    if(result === 'error') {
      if(msg.includes('already') && msg.includes('subscribed')) {
        setMessage("You're already subscribed")
      } else if(msg.includes('valid')) { 
        setMessage("Email seems to be invalid")

        setTimeout(() => {
          setMessage(null)
          inputRef?.current?.focus()
        }, 1750)
      } else {
        setMessage("Error, try again later")
      }
    }
  }

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if(!submitted) setEngaged(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ wrapperRef, submitted ]);

  return (
    <Mailchimp ref={ wrapperRef }>

      <Toggle
        engaged={ engaged }
        onClick={ () => {
          setEngaged(true)
          inputRef?.current?.focus()
        }}
      >
        { block.label }
      </Toggle>
      <Signup 
        engaged={ engaged }
        message={ message }
      >
        <InputWrapper>
          <Input 
            placeholder="Enter email"
            ref={ inputRef }
            onChange={ (e) => setEmail(e.target.value) }
          />
        </InputWrapper>
        <Submit 
          email={ email?.length > 0 }
          onClick={ (event) => handleSubmit(event, email) }
        >
          <Icon 
            icon={ ICONS.RIGHT } 
            color={ `var(--color-black)` } 
          />
        </Submit>
      </Signup>
      <Result message={ message }>
        { message }
      </Result>
    </Mailchimp>
  )
}

export default MailchimpComponent