import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import * as sanitizeHtml from 'sanitize-html';


import Mailchimp from './mailchimp'

import { ICONS } from '../utils/constants';
import Icon from '../components/icon'
import {
  Footer,
  MobileIcon,
  Column,
  Title,
  List
} from '../styled/footer'


const FooterComponent = ({ theme, margin, noMinHeight }) => {
  const data = useStaticQuery(graphql`
    query footer {
      wp {
        siteSettings {
          footer {
            columnFour {
              content {
                ... on WpSiteSettings_Footer_ColumnFour_Content_Text {
                  content
                  fieldGroupName
                }
                ... on WpSiteSettings_Footer_ColumnFour_Content_ExternalLink {
                  externalLink
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnFour_Content_InternalLink {
                  fieldGroupName
                  internalLink {
                    target
                    title
                    url
                  }
                }
                ... on WpSiteSettings_Footer_ColumnFour_Content_Email {
                  email
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnFour_Content_Phone {
                  fieldGroupName
                  label
                  phone
                }
                ... on WpSiteSettings_Footer_ColumnFour_Content_NewsletterSignup {
                  fieldGroupName
                  label
                }
              }
              title
              fieldGroupName
            }
            fieldGroupName
            columnOne {
              content {
                ... on WpSiteSettings_Footer_ColumnOne_Content_Text {
                  content
                  fieldGroupName
                }
                ... on WpSiteSettings_Footer_ColumnOne_Content_InternalLink {
                  fieldGroupName
                  internalLink {
                    target
                    title
                    url
                  }
                }
                ... on WpSiteSettings_Footer_ColumnOne_Content_ExternalLink {
                  externalLink
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnOne_Content_Email {
                  email
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnOne_Content_Phone {
                  fieldGroupName
                  label
                  phone
                }
                ... on WpSiteSettings_Footer_ColumnOne_Content_NewsletterSignup {
                  fieldGroupName
                  label
                }
              }
              fieldGroupName
              title
            }
            columnThree {
              content {
                ... on WpSiteSettings_Footer_ColumnThree_Content_Text {
                  content
                  fieldGroupName
                }
                ... on WpSiteSettings_Footer_ColumnThree_Content_InternalLink {
                  fieldGroupName
                  internalLink {
                    target
                    title
                    url
                  }
                }
                ... on WpSiteSettings_Footer_ColumnThree_Content_ExternalLink {
                  externalLink
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnThree_Content_Email {
                  email
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnThree_Content_Phone {
                  fieldGroupName
                  label
                  phone
                }
                ... on WpSiteSettings_Footer_ColumnThree_Content_NewsletterSignup {
                  fieldGroupName
                  label
                }
              }
              fieldGroupName
              title
            }
            columnTwo {
              content {
                ... on WpSiteSettings_Footer_ColumnTwo_Content_Text {
                  content
                  fieldGroupName
                }
                ... on WpSiteSettings_Footer_ColumnTwo_Content_InternalLink {
                  fieldGroupName
                  internalLink {
                    target
                    title
                    url
                  }
                }
                ... on WpSiteSettings_Footer_ColumnTwo_Content_ExternalLink {
                  externalLink
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnTwo_Content_Email {
                  email
                  fieldGroupName
                  label
                }
                ... on WpSiteSettings_Footer_ColumnTwo_Content_Phone {
                  fieldGroupName
                  label
                  phone
                }
                ... on WpSiteSettings_Footer_ColumnTwo_Content_NewsletterSignup {
                  fieldGroupName
                  label
                }
              }
              fieldGroupName
              title
            }
          }
        }
      }
    }
  
  `)

  const { footer } = data.wp.siteSettings

  return (
    <Footer theme={ theme } margin={ margin } noMinHeight={ noMinHeight }>
      <MobileIcon>
        <Icon 
          icon={ICONS.DOWN} 
          color={ `var(--color-black)` } 
        />
      </MobileIcon>

      <Column>
        <Title>{ footer.columnOne.title }</Title>
        <FooterBuilder content={ footer.columnOne.content } />
      </Column>     

      <Column>
        <Title>{ footer.columnTwo.title }</Title>
        <FooterBuilder content={ footer.columnTwo.content } />
      </Column>    

      <Column>
        <Title>{ footer.columnThree.title }</Title>
        <FooterBuilder content={ footer.columnThree.content } />
      </Column>    

      <Column>
        <Title>{ footer.columnFour.title }</Title>
        <FooterBuilder content={ footer.columnFour.content } />
      </Column>    

    </Footer>
  )
}

export default FooterComponent


const FooterBuilder = ({ content }) => {
  return (
    <List>
      { content.map(( block, i ) => {
        if (block && block.fieldGroupName) {
          const type = block.fieldGroupName
          const blockProps = {
            block,
          }

          const Default = () => (
            <div>
              <pre style={{ whiteSpace: 'normal', fontSize: '14px' }}>In Page Builder, the mapping of this component is missing: <strong>{type}</strong></pre>
            </div>
          )
          
          const blockList = {
            SiteSettings_Footer_ColumnOne_Content_ExternalLink : ExternalLink,
            SiteSettings_Footer_ColumnTwo_Content_ExternalLink : ExternalLink,
            SiteSettings_Footer_ColumnThree_Content_ExternalLink : ExternalLink,
            SiteSettings_Footer_ColumnFour_Content_ExternalLink : ExternalLink,

            SiteSettings_Footer_ColumnOne_Content_InternalLink : InternalLink,
            SiteSettings_Footer_ColumnTwo_Content_InternalLink : InternalLink,
            SiteSettings_Footer_ColumnThree_Content_InternalLink : InternalLink,
            SiteSettings_Footer_ColumnFour_Content_InternalLink : InternalLink,

            SiteSettings_Footer_ColumnOne_Content_Email : Email,
            SiteSettings_Footer_ColumnTwo_Content_Email : Email,
            SiteSettings_Footer_ColumnThree_Content_Email : Email,
            SiteSettings_Footer_ColumnFour_Content_Email : Email,

            SiteSettings_Footer_ColumnOne_Content_Phone : Phone,
            SiteSettings_Footer_ColumnTwo_Content_Phone : Phone,
            SiteSettings_Footer_ColumnThree_Content_Phone : Phone,
            SiteSettings_Footer_ColumnFour_Content_Phone : Phone,

            SiteSettings_Footer_ColumnOne_Content_Text : Text,
            SiteSettings_Footer_ColumnTwo_Content_Text : Text,
            SiteSettings_Footer_ColumnThree_Content_Text : Text,
            SiteSettings_Footer_ColumnFour_Content_Text : Text,

            SiteSettings_Footer_ColumnOne_Content_NewsletterSignup : Mailchimp,
            SiteSettings_Footer_ColumnTwo_Content_NewsletterSignup : Mailchimp,
            SiteSettings_Footer_ColumnThree_Content_NewsletterSignup : Mailchimp,
            SiteSettings_Footer_ColumnFour_Content_NewsletterSignup : Mailchimp,

            Default: Default,
          }

          const BlockTag = blockList[type] ? blockList[type] : blockList['Default']
          
          return (
            <li key={ i }>
              <BlockTag { ...blockProps } />
            </li>
          )
        }
      })}
    </List>
  )
} 

const ExternalLink = ({ block }) => (
  <a href={ block.externalLink }>{ block.label }</a>
)

const InternalLink = ({ block }) => {
  const url = block.internalLink.url.includes(process.env.CMS_URL) ? block.internalLink.url.replace(process.env.CMS_URL, '') : block.internalLink.url
  // const title = sanitizeHtml(block.internalLink.title )

  return (
    <Link to={ url } dangerouslySetInnerHTML={{__html: sanitizeHtml(block.internalLink.title ) }} />
  )
}

const Email = ({ block }) => (
  <a href={`mailto: ${ block.email }`}>{ block.label }</a>
)

const Phone = ({ block }) => (
  <a href={`tel: ${ block.phone }`}>{ block.label }</a>
)

const Text = ({ block }) => (
  <p>{ block.content }</p>
)