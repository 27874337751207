import React from 'react'

// Our global theme context with default values
export const Context = React.createContext({
  lockScroll: false,
  setLockScroll: () => {},
})

// Theme provider component with state
const Provider = props => {
  const [lockScroll, setLockScroll] = React.useState(false)
  const [searchIsOpen, setSearchIsOpen] = React.useState(false)
  
  const value = { 
    lockScroll, 
    setLockScroll,
    searchIsOpen,
    setSearchIsOpen
  }

  return (
    <Context.Provider value={ value }>
      {props.children}
    </Context.Provider>
  )
}

// Exports a Provider wrapper
export default ({ element }) => <Provider>{element}</Provider>