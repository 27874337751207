import * as React from 'react'
import styled from '@emotion/styled'
import { 
  componentStyle,
  linkStyle
} from './general'

import { sm, md, lg } from '../utils/breakpoints'

export const Footer = styled('footer')`
  ${ componentStyle };
  //padding: 14px;
  // margin-top: 120px;
  line-height: 1.22;
  // margin-top: 80px;
  min-height: ${ props =>
    props.noMinHeight ? `initial` : `calc(100vh - 80px)` };

  margin-top: ${ props =>
    props.margin === 'none' ? `0` : `80px` };

  /*
  color: ${ props =>
    props.theme === 'dark' ? `var(--color-white)` : `var(--color-black)` };
  */

  a {
    ${ linkStyle }

    &:hover {
      color: ${ props =>
        props.theme === 'dark' ? `var(--color-white)` : `var(--color-red)` };
    }
  }

  ${ lg } {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    min-height: initial;
    padding-bottom: 20px;
    // margin-top: 80px;
    // min-height: calc(100vh - 80px); /* ToDo: Should be aware of state of header */
  }
`

export const MobileIcon = styled('section')`
  margin-bottom: 56px;

  ${ lg } {
    display: none;
  }
`

export const Column = styled('section')`
  margin-top: 42px;
  line-height: 22px; // ToDo: Should not be on section level
  user-select: none;
  
  &:first-of-type {
    margin-top: 0;
  }
  
  p {
    margin: 0;
    white-space: pre;
  }

  ${ lg } {
    margin-top: 0;
  }
`

export const Title = styled('h4')`
  text-transform: uppercase;
  margin-bottom: 14px;
  user-select: none;
`

export const List = styled('ul')`

  li {
    list-style-type: none;
  }
`
