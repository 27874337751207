import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  CookieBar,
  Inner,
  Header,
  Heading,
  Content,
  Actions,
  PrimaryAction,
  SecondaryAction
} from '../styled/cookieBar'

const Component = ({ intro }) => {
  const [isDismissed, setIsDismissed] = React.useState(false)

  const data = useStaticQuery(graphql`
    query PrivacyPolicyPage {
      allWpPage(filter: {isPrivacyPage: {eq: true}}) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `)

  const slug = data.allWpPage.edges[0]?.node?.slug ?? data.allWpPage.edges[0].node.slug

  return (
    <CookieBar intro={ intro } isDismissed={ isDismissed }>
      <Inner>
        <Header>
          <Heading>This site uses cookies</Heading>
          <SecondaryAction visibleSM onClick={ () => setIsDismissed(true) }>
              Close
          </SecondaryAction>
        </Header>
        <Content>
          By continuing to use this site you agree to our use of cookies.
        </Content>
        <Actions>
          { slug &&
            <PrimaryAction to={`/${slug}`}>
              Privacy Policy
            </PrimaryAction>
          }
          <SecondaryAction hiddenSM onClick={ () => setIsDismissed(true) }>
            Close
          </SecondaryAction>
        </Actions>
      </Inner>
    </CookieBar>
  )
}

export default Component