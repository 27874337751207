import * as React from 'react'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { Link } from 'gatsby'
import { 
  componentStyle,
  // linkStyle
} from './general'

import { sm, md, lg } from '../utils/breakpoints'

export const Header = styled('header')`
  position: sticky;
  top: 0;
  z-index: 100;

  display: flex;
  align-items: flex-end;

  width: 100%;
  height: 65px;
  background-color: var(--color-red);

  transition: transform 0.25s ease-in-out;

  transform: ${ props =>
    props.headerIsExpanded ? `translateY(0px);` : `translateY(-16px);` };
`

export const Inner = styled('div')`
  position: relative;
  width: 100%;
  ${ componentStyle };
`

const reveal = keyframes`
  0% {
    opacity: 0;
  }
  
  55% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
`

const revealAndStay = keyframes`
  0% {
    opacity: 0;
  }
  
  55% {
    opacity: 1;
  }
`

export const animationCss = css`
  animation-name: ${ reveal };
  animation-duration: 0.9s;
  animation-delay: calc(var(--animation-order) * 0.55s);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
`

export const animationAndStayCss = css`
  animation-name: ${ revealAndStay };
  animation-duration: 0.9s;
  animation-delay: calc(var(--animation-order) * 0.55s);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
`

export const Nav = styled('ul')`
  display: flex;
  justify-content: space-between;

  position: relative;
  width: 100%;

  /*
  li {
    ${props => {
      if ( props.intro ) {
        return `
          opacity: 0;
      `
      }
    }}
  }
  */


  &.intro {
    li a,
    li button {
      pointer-events: none;
    }

    /*
    li a {
      ${ animationCss };
    }
    */

    li:nth-of-type(1) a,
    li:nth-of-type(2) a,
    li:nth-of-type(3) a {
      ${ animationCss };
    }

    li:nth-of-type(4) a {
      ${ animationAndStayCss }
    }

    li button {
      opacity: 0;
    }
  }

  ${ lg } {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
  }
`

export const NavItem = styled('li')`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  user-select: none;

  /*
  pointer-events: ${ props =>
    props.intro ? `none` : `auto` };

  &.search {
    opacity: ${ props =>
      props.intro ? `0` : `1` };
  }
  */

  ${ lg } {
    &.search {
      position: absolute;
      right: 0;
    }
  }
`

export const navLinkStyle = css`
  display: block;
  
  //padding: 4px;
  color: var(--color-white);
  text-transform: uppercase;
  line-height: 1;
  opacity: 0.4;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.25s ease-in-out;

  &.active {
    opacity: 1;
    
    /*
    opacity: ${ props =>
      props.intro ? `0.4` : `1` };
    */
  }
`

export const NavLink = styled(Link)`
  ${navLinkStyle}

  @media (hover: hover) {
    &:hover {
      transform: translateY(-5px);

      &.active {
        transform: none;
      }
    }
  }
`

export const ButtonSearch = styled('button')`
  ${navLinkStyle}
  outline: none;
  margin-bottom: 1px;

  &:hover {
    opacity: 1;
  }

  &:active,
  &:focus {
    outline: none;
  }
`